footer{
  background-color: #f9f9f9;
}
.Navbar{
  background-color: #f9f9f9;
 
}

.image-carusel{
  height: 600px;
}
.home-login{
  padding: 50px
}
